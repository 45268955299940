import React from "react";
import styled from "styled-components";
import SEO from "../../components/Seo";
import Layout from "../../components/Layout";
import { useAlternateLangs } from "../../components/Hreflangs";
import MainContent from "../../components/MainContent";
import Breadcrumb from "../../components/Breadcrumb";
import H from "../../components/Headline";
import RelatedContentCardFlexBig from "../../components/RelatedContentCardFlexBig";

const StyledMiddle = styled.div`
  h1 {
    text-align: center;
  }

  .cardcontainer {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
  }

  margin: 0vmin 1vmin;
  .container {
  }

  .related-content-card {
    max-width: 27%;
    margin-right: 0rem;
    margin-bottom: 2rem;
  }
  @media all and (max-width: 900px) {
    .related-content-card {
      max-width: 43%;
    }
  }

  @media all and (max-width: 500px) {
    .cardcontainer {
      flex-direction: column;
      align-items: center;
    }

    .related-content-card {
      max-width: 95%;
      min-width: 80%;
    }
  }
`;

const breadCrumbLevels = {
    Accueil: "/fr",
    Services: "/fr/les-services"
  };
  
// Hreflang data
const alternateLangs = useAlternateLangs(
  // replace with pages x-default
  "/en/services"
);

  const Services = props => (
    <Layout location={props.location} alternateLangs={alternateLangs}>
  
      <React.Fragment>
        <SEO
          title="Services Freelance"
          lang="fr"
          description="Services freelance pour l'Analytics, le SEO, la gestion des tags et plus encore. Contactez-moi pour un devis !"
          alternateLangs={alternateLangs}
        />
        <MainContent maxWidth="1000">
          <StyledMiddle>
            <Breadcrumb breadCrumbLevels={breadCrumbLevels} className="breadcrumb" />
            <div className="container">
              <H as="h1">Je peux vous aider avec...</H>
              <div className="cardcontainer">
                {/* TODO: just make an object with the needed data and then loop over it */}
                <RelatedContentCardFlexBig
                  imageName="code"
                  title="Analytics"
                  link="/fr/consultant-google-analytics"
                  description="Nous n'allons pas nous améliorer si nous ne mesurons pas ce qui se passe sur notre site."
                />
                <RelatedContentCardFlexBig
                  imageName="desk"
                  title="SEO"
                  link="/fr/seo-freelance"
                  description="Tout d'une seule main : SEO on-page, technique et off-page."
                />
                <RelatedContentCardFlexBig
                  imageName="coworking"
                  title="Gestion des Tags"
                  link="/fr/consultant-google-tag-manager"
                  description="Je peux vous aider à intégrer et automatiser les services en fonction de vos données."
                />
              </div>
            </div>
          </StyledMiddle>
        </MainContent>
      </React.Fragment>
  
    </Layout>
  );
  
  export default React.memo(Services);
  